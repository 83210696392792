<template>
	<a-drawer width="50%" title="我的客户" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
		<div class="my-custom">
			<div class="my-custom-info">
				<div class="info">
					<span class="nickname">{{record.name}}</span>
					<a-divider type="vertical" />
					<span class="phone">{{record.phone}}</span>
					<a-divider type="vertical" />
					<a-icon type="phone" class="icon" @click="callPhone(record.phone)" />
					<a-divider type="vertical" />
					<a-button type="primary" size="small" @click="getCloudCall(record.phone)">云拨打</a-button>
				</div>
				<div class="other-info">
					<div class="orher-info-item">
						<div>
							<a-icon type="file-text" />
							<span class="info-tips">工作单位：</span>
						</div>
						<div class="info-content">{{record.work}}</div>
					</div>
					<div class="orher-info-item">
						<div>
							<a-icon type="environment" />
							<span class="info-tips">地区：</span>
						</div>
						<div class="info-content">{{record.area}}</div>
					</div>
					<div class="orher-info-item">
						<div>
							<a-icon type="phone" />
							<span class="info-tips">上次通话状态：</span>
						</div>
						<div class="info-content">
							<!-- {{record.lastCallStatus}} -->
							<a-button
								:class="{'grey':record.lastCallStatus==0,'red':record.lastCallStatus==1,'green':record.lastCallStatus==2}"
								size="small">{{record.lastCallStatus==0?'未拨打':record.lastCallStatus==1?'未接':'已接'}}</a-button>
						</div>
					</div>
				</div>
			</div>
			<div class="follow-records">
				<div class="left">
					<div class="title">
						<img src="/src/assets/images/1.png" alt="">
						<span>跟进记录</span>
					</div>
					<div class="public-title">跟进内容：</div>
					<a-textarea placeholder="请输入" v-model="followUpRecords" :auto-size="{ minRows: 6, maxRows: 6 }" />
					<div class="public-title" style="color:#333;">下次跟进时间：</div>
					<a-date-picker @change="onChange" style="width:100%;" v-model="followUpTime" />
					<div class="follow-save">
						<a-button type="danger" @click="handleSave" ghost>确认</a-button>
					</div>
				</div>
				<div class="right">
					<div class="upload-list">
						<div class="upload-item" v-for="(item,index) in followList" :key="index">
							<div>
								<span class="title">{{item.userName}}</span>
								<span class="upload-time">更新客户信息时间：{{item.updateTime}}</span>
							</div>
							<div class="upload-content">
								<div class="contents-upload">
									<span class="follow-history-title">跟进内容</span>
									<span class="follow-history-content">{{item.followUpRecords}}</span>
								</div>
								<div class="next-time">
									<span class="follow-history-title">下次跟进时间</span>
									<span class="follow-history-content">{{item.followUpTime}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="custom-situation">
				<div class="title">
					<img src="/src/assets/images/1.png" alt="">
					<span>客户情况</span>
				</div>
				<div class="custom-situation-list">
					<div class="custom-situation-item">
						<div>近一年开票金额(万)：</div>
						<a-input placeholder="请输入" type="number" v-model="invoiceAmount"></a-input>
					</div>
					<div class="custom-situation-item">
						<div>近一年纳税金额(万)：</div>
						<a-input placeholder="请输入" type="number" v-model="taxAmount"></a-input>
					</div>
					<div class="custom-situation-item">
						<span>是否加微信成功：</span>
						<a-radio-group name="radioGroup" v-model="addWechat">
							<a-radio :value="1">是</a-radio>
							<a-radio :value="0">否</a-radio>
						</a-radio-group>
					</div>
					<div class="custom-situation-item">
						<span>是否继续跟进：</span>
						<a-radio-group name="radioGroup" v-model="fundingRequirements">
							<a-radio :value="1">是</a-radio>
							<a-radio :value="0">否</a-radio>
						</a-radio-group>
					</div>
					<div class="custom-situation-item">
						<span>是否可提供为转介绍线索：</span>
						<a-radio-group name="radioGroup" v-model="clue">
							<a-radio :value="1">是</a-radio>
							<a-radio :value="0">否</a-radio>
						</a-radio-group>
					</div>
				</div>
			</div>
			<div class="cell-phone">
				<div class="title">
					<img src="/src/assets/images/1.png" alt="">
					<span>智能呼叫</span>
				</div>
				<div class="input-phone">
					<a-input v-model="phone" size="large" placeholder="请输入"></a-input>
					<!-- <a-input-search v-model="phone" size="large" /> -->
					<a-button type="primary" size="large" @click="callPhone(phone)">拨打电话</a-button>
					<a-divider type="vertical" />
					<a-button type="primary" size="large" @click="getCloudCall(phone)">云拨打</a-button>
					<!-- <a-input-search enter-button="拨打电话" size="large" @search="callPhone" /> -->
				</div>
			</div>
			<div class="call-list">
				<a-table :dataSource="dataSource" :columns="columns" :pagination="false" />
			</div>
			<div class="sub-btn">
				<!-- <a-button @click="nextUser" class="submint" type="danger">下一个</a-button> -->
				<a-divider type="vertical" />
				<a-button @click="getSave" class="save">保存</a-button>
				<a-divider type="vertical" />
				<a-button @click="handleSubmintDatabases" class="submint">转入线索新名单公海</a-button>
			</div>
		</div>
	</a-drawer>
</template>

<script>
	import {
		followAdd,
		followHistory,
		submitIntentLibrary,
		updateRoster,
		callByPlatform,
		batchHighSeas,
		getRoster,
		callMobile,
		calllisthistory
	} from '@/api/roster/roster'
	export default {
		name: 'FollowUpModel',
		props: ['list'],
		data() {
			return {
				open: false,
				record: {},
				followUpTime: '', //下次跟进时间
				followUpRecords: '', //跟进内容
				followList: [], //跟进记录
				addWechat: 0, //是否加微信成功,0-否,1-是
				fundingRequirements: 0, //是否有资金需求,0-否,1-是
				clue: 0, //是否可提供为转介绍线索,0-否,1-是
				invoiceAmount: 0, //开票金额(万)
				taxAmount: 0, //纳税金额(万)
				phone: '',
				selectIndex: null,
				dataSource: [],
				columns: [{
						title: '序号',
						ellipsis: true,
						width: 60,
						align: 'center',
						customRender: function(text, record, index) {
							return index + 1
						}
					},
					{
						title: '拨打号码',
						dataIndex: 'phone',
						width: 200,
						ellipsis: true,
						align: 'center'
					},
					{
						title: '拨打时间',
						dataIndex: 'createTime',
						width: 200,
						ellipsis: true,
						align: 'center'
					}
				]
			}
		},
		methods: {
			getcalllisthistory() {
				console.log('查询新名单与手机号关联列表')
				calllisthistory({
					rosterId: this.record.rosterId
				}).then(res => {
					if (res.code == '20001') {
						console.log('查询新名单与手机号关联列表', res)
						this.dataSource = res.data
					}
				})
			},
			// 编辑保存
			getSave() {
				let {
					invoiceAmount,
					taxAmount,
					fundingRequirements,
					clue,
					addWechat
				} = this;
				let data = {
					addWechat: addWechat,
					fundingRequirements: fundingRequirements,
					clue: clue,
					invoiceAmount: invoiceAmount,
					taxAmount: taxAmount,
					id: this.record.rosterId
				}
				updateRoster(data).then(res => {
					if (res.success) {
						this.$message.success('保存成功');
						this.nextUser()
					}
				})
			},
			// 提交意向库
			handleSubmintDatabase() {
				let {
					invoiceAmount,
					taxAmount,
					fundingRequirements,
					clue,
					addWechat
				} = this;
				if (!invoiceAmount) {
					this.$message.error('请输入近一年开票金额');
					return
				}
				if (!taxAmount) {
					this.$message.error('请输入近一年纳税金额');
					return
				}
				let data = {
					addWechat: addWechat,
					fundingRequirements: fundingRequirements,
					clue: clue,
					invoiceAmount: invoiceAmount,
					taxAmount: taxAmount,
					id: this.record.rosterId
				}
				submitIntentLibrary(data).then(res => {
					if (res.success) {
						console.log('提交意向库', res);
						// this.open = false;
						// this.$emit('ok')
						this.nextUser()
					}
				})
			},
			handleSubmintDatabases() {
				let data = {
					idList: [{
						id: this.record.id
					}],
					type: 4
				}
				batchHighSeas(data).then(res => {
					console.log('删除线索客户', res);
					this.open = false;
					this.$emit('ok')
				})
			},
			// 提交跟进记录
			handleSave() {
				let data = {
					followUpTime: this.followUpTime,
					followUpRecords: this.followUpRecords,
					rosterId: this.record.rosterId
				}
				followAdd(data).then(res => {
					console.log('提交跟进', res)
					if (res.success) {
						this.$message.success(res.message);
						this.followUpTime = '';
						this.followUpRecords = '';
						this.getFollowHistory()
					}
				})
			},
			// 跟进记录
			getFollowHistory() {
				followHistory({
					rosterId: this.record.rosterId
				}).then(res => {
					console.log('跟进记录', res)
					if (res.success) {
						this.followList = res.data.slice(0, 2);
						console.log(this.followList)
					}
				})
			},
			// 直接拨打电话
			callPhone(phone) {
				var mobile = '';
				if (phone) {
					mobile = phone;
				} else {
					mobile = this.phone
				}
				callMobile({
					phone: mobile,
					rosterId: this.record.rosterId
				}).then(res => {
					console.log('拨打电话', res);
				})
			},
			// 云拨打
			getCloudCall(phone) {
				console.log(phone)
				let agent = localStorage.getItem('SEATSNUM')
				callByPlatform({
					agent: agent,
					phone: phone,
					rosterId: this.record.rosterId
				}).then(res => {
					if (res.success) {
						console.log('云拨打', res);
					}
				})
			},
			// 下次更近时间
			onChange(e) {
				this.followUpTime = e.format('YYYY-MM-DD')
				console.log(this.followUpTime)
			},
			follow(record, index) {
				this.open = true;
				this.record = record;
				this.selectIndex = index;
				getRoster({
					id: record.rosterId
				}).then(res => {
					console.log('用户详情', res);
					this.addWechat = res.data.addWechat;
					this.fundingRequirements = res.data.fundingRequirements;
					this.clue = res.data.clue;
					this.invoiceAmount = res.data.invoiceAmount;
					this.taxAmount = res.data.taxAmount;
				})
				this.getFollowHistory()
				this.getcalllisthistory();
			},
			nextUser() {
				if (this.selectIndex + 1 > this.list.length - 1) {
					this.$emit('next');
					return false
				}
				this.selectIndex++;
				this.record = this.list[this.selectIndex];
				getRoster({
					id: this.record.rosterId
				}).then(res => {
					console.log('用户详情', res);
					this.addWechat = res.data.addWechat;
					this.fundingRequirements = res.data.fundingRequirements;
					this.clue = res.data.clue;
					this.invoiceAmount = res.data.invoiceAmount;
					this.taxAmount = res.data.taxAmount;
				})
				this.getFollowHistory()
			},
			onClose() {
				this.open = false;
				this.$emit('close')
				this.$emit('ok')
			},
			uploadList(arr) {
				this.selectIndex = 0;
				this.record = this.list[this.selectIndex];
				getRoster({
					id: this.record.rosterId
				}).then(res => {
					console.log('用户详情', res);
					this.addWechat = res.data.addWechat;
					this.fundingRequirements = res.data.fundingRequirements;
					this.clue = res.data.clue;
					this.invoiceAmount = res.data.invoiceAmount;
					this.taxAmount = res.data.taxAmount;
				})
				this.getFollowHistory()
			}
		}
	}
</script>

<style scoped>
	.my-custom {}

	.my-custom-info {
		background: #eee;
		padding: 24px;
	}

	.info {}

	.nickname {
		font-size: 24px;
		color: #B55C01;
	}

	.phone {
		font-size: 16px;
		color: #B55C01;
	}

	.icon {
		font-size: 16px;
		color: #B55C01;
	}

	.other-info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 25px;
	}

	.orher-info-item {
		width: 30%;
	}

	.info-tips {
		font-size: 14px;
		margin-left: 5px;
	}

	.info-content {
		font-size: 16px;
		color: #B55C01;
	}

	.grey {
		background: #eee;
	}

	.red {
		background: red;
		color: #fff;
	}

	.green {
		background: #7CFC00;
		color: #fff;
	}

	.follow-records {
		padding: 20px 0;
		margin-top: 20px;
		border-top: 1px solid #eee;
		border-bottom: 1px solid #eee;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.left,
	.right {
		width: 50%;
		padding: 0 20px;
	}

	.title {
		font-size: 16px;
		font-weight: bold;
	}

	.public-title {
		font-size: 15px;
		margin: 20px 0 15px;
	}

	.follow-save {
		text-align: right;
		margin-top: 20px;
	}

	.right {}

	.upload-list {}

	.upload-item {
		margin-bottom: 20px;
	}

	.upload-item:last-child {
		margin-bottom: 0;
	}

	.upload-time {
		margin-left: 10px;
	}

	.upload-content {
		background: #F8F8F8;
		padding: 24px 16px;
		margin-top: 16px;
	}

	.contents-upload,
	.next-time {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.next-time {
		margin-top: 16px;
	}

	.follow-history-title {
		display: inline-block;
		width: 100px;
	}

	.follow-history-content {
		display: inline-block;
		width: 300px;
	}

	.custom-situation {
		padding: 20px 0;
	}

	.custom-situation-list {
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		flex-wrap: wrap;
		border-bottom: 1px solid #eee;
		margin-top: 20px;
	}

	.custom-situation-item {
		width: 30%;
		margin-bottom: 16px;
	}

	.custom-situation-item div {
		margin-bottom: 10px;
	}

	.custom-situation-item:nth-child(3n-1) {
		margin-left: 5%;
		margin-right: 5%;
	}

	.input-phone {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		width: 60%;
	}

	.call-list {
		margin-top: 20px;
	}

	.sub-btn {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-top: 16px;
		padding: 10px 20px;
		z-index: 1000;
		background: #fff;
	}

	.submint {
		color: #fff;
		background: linear-gradient(300deg, #9C7731 0%, #E5C17D 100%);
	}

	.save {
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #BE984E;
		color: #BE984E;
	}
</style>